export function addSourcePlan(data) {
  return window.$axios.post(`${window.service.api}/plan/plan_source`, data);
}

export function updateSourcePlan(data) {
  return window.$axios.put(`${window.service.api}/plan/plan_source`, data);
}

export function testSource(data) {
  return window.$axios.post(`${window.service.api}/plan/test_source`, data)
}

// 信源屏蔽-列表
export function shieldSourceList(params) {
  return window.$axios.get(`${window.service.api}/user/source`, { params: params })
}

// 信源屏蔽-新增
export function shieldSource(data) {
  return window.$axios.post(`${window.service.api}/user/source`, data);
}

// 根据source_name请求uuid
export function askSource_uuid(data) {
  return window.$axios.post(`${window.service.api}/user/source/ask_uuid`, data);
}

// 信源屏蔽-删除
export function dropShieldSource(params) {
  return window.$axios.delete(`${window.service.api}/user/source`, { params: params })
}

// 信息列表
export function sourceMessages(params) {
  return window.$axios.get(`${window.service.api}/plan/plan_source_msg`, { params: params })
}
// 方案状态
export function changeState(data) {
  return window.$axios.put(`${window.service.api}/plan/plan_source_enable`, data)
}

// 删除方案
export function deleteSourcePlan(params) {
  return window.$axios.delete(`${window.service.api}/plan/plan_source`, { params: params })
}

// 信源总量
export function statisticsCount(params) {
  return window.$axios.get(`${window.service.api}/plan/plan_source_count`, { params: params })
}

// 信源概览
export function statisticsSummary(params) {
  return window.$axios.get(`${window.service.api}/plan/plan_source_summary`, { params: params })
}

// 信源声量走势
export function statisticsCountStat(params) {
  return window.$axios.get(`${window.service.api}/plan/plan_source_count_stat`, { params: params })
}

// 信源敏感信息走势
export function statisticsCountStatNeg(params) {
  return window.$axios.get(`${window.service.api}/plan/plan_source_count_stat_neg`, { params: params })
}

// 信源声量占比
export function statisticsCountStatPercent(params) {
  return window.$axios.get(`${window.service.api}/plan/plan_source_count_stat_percent`, { params: params })
}

// 信源情感分布
export function statisticsCountStatDistributed(params) {
  return window.$axios.get(`${window.service.api}/plan/plan_source_count_stat_distributed`, { params: params })
}

// 信源关键词
export function statisticsWords(params) {
  return window.$axios.get(`${window.service.api}/plan/plan_source_words`, { params: params })
}

// 信源关键文章
export function statisticsArticles(params) {
  return window.$axios.get(`${window.service.api}/plan/plan_source_articles`, { params: params })
}

// 相关账号
export function statisticsRelationAccount(params) {
  return window.$axios.get(`${window.service.api}/plan/plan_source_relation_account`, { params: params })
}

// 相关事件
export function statisticsRelationEvent(params) {
  return window.$axios.get(`${window.service.api}/plan/plan_source_relation_event`, { params: params })
}

// 相关实体
export function statisticsEntity(params) {
  return window.$axios.get(`${window.service.api}/plan/plan_source_entity`, { params: params })
}
